function Projects() {
    return (
        <div>
        <h2>Projects</h2>
        <p>
            <b>Vincent</b>
            <br />
            We did a group project which has come to a standstill at the moment.
            Read all about it <a href="https://github.com/OnnoH/Vincent" target="_blank">here</a>.
        </p>
    </div>
    )

}

export default Projects