function Testimonials() {

    return (
        <div>
            <h2>What the members think</h2>
            <p>
                <i>Lieke:</i> I am always looking forward to meet the group to connect over tech stuff and expose myself to an environment that stimulates me to learn new things on coding, electronics, and in the end be able to prototype and tinker with sensors. I also always have a lot of fun with the drinks after the meetup. I wouldn't have taken up my Arduino and coding skills if it wasn't for this group, it get's so much easier to learn together :)
            </p>
            <p>
                <i>Georgios:</i> This is a great meet up for people who just want to find same minded people creating projects for Raspberry Pi or Arduino devices, share ideas and ask questions. Trust me all levels are welcome, I am talking as the newest and most inexperienced person of the group.
            </p>
            <p>
                <i>Cesar:</i> This is an open space on which new ideas are always welcome regardless of the level/background , it's a space for enthusiastic people contributing to group and personal tech interests
            </p>
            <p>
                <i>Rafael:</i> An awesome group of people with common interest in Arduino, Raspberry Pi, programming and tech in general. Great meetup to show your projects, discuss ideas and solutions and work together on the group project if you want.
            </p>
            <p>
                <i>Onno:</i> I use these meetups to work on pet projects that don't get the attention during the day to day business. If we meetup on Saturday there is always a good vibe: we discuss, we program, we learn, we laugh (and have drinks afterwards almost every time ;) so I can highly recommend this one!
            </p>
        </div>
    )
}

export default Testimonials