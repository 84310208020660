function Manifesto() {
  return (
    <div>
      <h2>Manifesto</h2>
      <ul>
        <li>
          Explore and experiment is our motto, failures do not exist, but we&apos;re
          not surprised if you bang your head on the table now and then
        </li>
        <li>
          Although we are about Arduino&apos;s and Pi&apos;s, ESP&apos;s and other controllers
          are also welcome
        </li>
        <li>
          We communicate in English (or Haags dialect), but you can bring any
          language of choice for your microcontroller
        </li>
        <li>
          We plan on having a meetup once a month, but it&apos;s subject to the
          availability of a meetup space at the library
        </li>
        <li>
          Collaboration and knowledge transfer is encouraged, but playing
          solitaire is also fine
        </li>
        <li>
          Having fun is mandatory, having a drink afterwards is not (but is in
          general gezellig)
        </li>
      </ul>
    </div>
  );
}

export default Manifesto